// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-node-article-js": () => import("/builds/geertdv/sj-verzekeringen/__themes/frontend/src/templates/node-article.js" /* webpackChunkName: "component---src-templates-node-article-js" */),
  "component---src-templates-node-page-js": () => import("/builds/geertdv/sj-verzekeringen/__themes/frontend/src/templates/node-page.js" /* webpackChunkName: "component---src-templates-node-page-js" */),
  "component---src-templates-node-taxonomy-js": () => import("/builds/geertdv/sj-verzekeringen/__themes/frontend/src/templates/node-taxonomy.js" /* webpackChunkName: "component---src-templates-node-taxonomy-js" */),
  "component---src-pages-preview-js": () => import("/builds/geertdv/sj-verzekeringen/__themes/frontend/src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */)
}

